<template>
  <!--<div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>-->
  <router-view />
</template>

<style lang="scss">
:root {
  --fond_card: rgba(28, 40, 51, 0.9);
  --text_card: white;
}

html body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}

#app {
  font-family: Calibri, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*.tableau1 {

  thead{

  }
  tbody{

  }
  tr{

  }
  td{

  }

}*/

.tableau2 {
  margin: 15px 0;

  tr {
    padding: 15px;
  }
  td {
    padding: 8px 10px;
    white-space: pre;
    vertical-align: top;
  }
}

.btn-horiz {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  height: 40px;
  padding: 4px;
  border: lightgray 1px solid;
  border-radius: 3px;
  font-size: 0.7em;
  margin: 10px 10px 5px 0;
  background-color: rgb(233, 233, 233);
  box-shadow: 3px 3px 5px 0px #c7c7c7;
  cursor: pointer;
  transition: 0.3s ease;
  text-align: right;
  overflow: hidden;
  z-index: 1;
  color: black;

  &:hover {
    /*background-color: rgb(240, 255, 255);*/
    box-shadow: inset 2px 2px 5px 0px #c7c7c7;
    
  }

  img {
    width: 28px;
    height: 28px;

    p {
      padding: 0 0 0 4px;
    }
  }

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    height: 150%;
    width: 10%;
    top: -10px;
    left: -12px;
    transform: rotateZ(25deg);
    background-color: rgb(164, 241, 255);

    transition: 0.2s ease-in-out;
    box-shadow: inset 2px 2px 5px 0px #9b9b9b;
  }

  &:hover::before {
    left: 105%;
  }

  &:active {
    background-color: white;
    img {
      scale: 1.2;
    }
  }
}

select {
  width: 50%;
  border: 1px solid lightgray;
  padding: 3px 2px;
}

.tab_img {
  cursor: pointer;
  opacity: 0.8;
  margin: 0 5px 0 0;

  img {
    width: 18px;
    height: 18px;
  }

  &:hover {
    opacity: 1;
  }
}

.enLigne {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}

.enLignePetit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
}

.enLigne-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}

.enLigneCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px 0;
}

.petit-btn {
  width: 25px;
  height: 25px;
  border: gray 1px solid;
  border-radius: 3px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3px 8px 0 3px;
  cursor: pointer;

  &:hover {
    background-color: rgb(131, 240, 248);
  }

  &:active {
    background-color: rgb(89, 236, 247);
  }

  img {
    width: 22px;
    height: 22px;
  }
}

.champtxt {
  display: block;
  width: 99%;
  margin: 4px 0 0 0;
  label {
    margin: 15px 0 6px 0;
    font-size: 0.8em;
    padding: 0.3em 0;
    color: white;
  }

  input {
    padding: 0.3em;
    border: lightgray 1px solid;
    width: 98%;
    border-radius: 3px;
  }

  textarea {
    border: lightgray 1px solid;
    width: 98%;
    border-radius: 3px;
    padding: 0.3em;
  }

  select {
    width: 55%;
    border: lightgray 1px solid;
    border-radius: 3px;
    padding: 0.3em;
  }
}

.recherche {
  margin: 8px;
  padding: 10px;
  border: gray 1px solid;
  border-radius: 5px;
  font-size: 0.8em;
  background-color: rgba(37, 37, 37, 0.85);
  color: white;
  input {
    padding: 0.3em;
    border-radius: 3px;
    border: gray 1px solid;
    color: black;
    text-align: center;
  }
}

.titreEcran {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  background-color: rgba(247, 247, 247, 0.7);
}

.attente {
  width: 25px;
  height: 25px;
  margin: auto;
  border: 1px solid white;
  transform: skew(70deg);
  -webkit-transform: scale(0.6) skew(70deg);
  -moz-transform: scale(0.6) skew(70deg);
  border: 2px solid #ffffff;
  animation: rot360 2s infinite;
}

@keyframes rot360 {
  0% {
    transform: rotateZ(0deg) scale(0.4) skew(50deg);
  }
  100% {
    transform: rotateZ(360deg) scale(0.4) skew(50deg);
  }
}


.erreur {
  width: 80%;
  margin: 5px 0;
  text-align: center;
  border: 2px solid red;
  background-color: rgb(252, 191, 191);
  color:red;
  font-weight: 500;
  padding: 0.5em 2em;
  
}


.alert {
  
  width: 99%;
  opacity: 1;
  background-color: rgb(253, 199, 98);
  font-weight: 500;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5em;
  margin: 5px 2px;
  color: #0f0f0f;

  span {
    text-align: center;
    margin: 1em 1em 1em 0;
  }

  button{
    min-width: 86px;
    border: 1px solid gray;
    background-color: rgb(255, 186, 58);
    padding: 0.5em 1em;
    margin: 0 1em 2px 0;
    cursor: pointer;
    transition: 0.3s ease-in-out;
     &:hover {
    background-color: rgb(255, 176, 29);
  }
  }

 

}

.encharge {
  width: 40px;
  height: 40px;
  margin: 10px auto;
  text-align: center;
  background: url('/assets/charge.gif');
  background-size: cover;
}

.btn_texte {
  padding: 0.5em 1em;
  border: 1px solid #c2c2c2;
  background-color: rgb(233, 233, 233);
  box-shadow: 3px 3px 5px 0px #c7c7c7;
  cursor: pointer;
  transition: 0.2s ease;
  text-align: center;
  overflow: hidden;
  color: black;
  cursor: pointer;
  margin: 5px 3px;
  opacity: 0.75;

  &:hover {
    /*background-color: rgb(240, 255, 255);*/
    box-shadow: inset 2px 2px 5px 0px #c7c7c7;
    opacity: 1;
  }
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

</style>
