import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    loginId: 0,
    login: "",
    lstUser: [],
    lstClient: [],
    server: "https://atom-dev.net/API_AtomGC",
    //server: 'http://13.38.82.232:5022'
    //server: 'http://localhost:5022'
  },
  mutations: {
    SauveLogin(state, info) {
      console.log("store enreg:" + info.login);
      state.loginId = info.idUser;
      state.login = info.login;
    },
    /* ListeParams(state) {
      axios({
        method: "get",
        url: state.server + "/api/general/params",
      })
          .then((reponse) => {
          console.log(reponse);
          state.lstUser = reponse.data.listeUsers
          state.lstClient = reponse.data.listeClients
          //this.lstRech = reponse.data;
          //console.log(this.lstClient[0].id);
      })
          .catch((erreur) => {
          console.log(erreur);
      });
    } */
  },
  actions: {},
  modules: {},
});
