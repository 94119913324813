<template>
  <div class="home">
    <div class="anim">
      <span class="anim-txt"> ATOM </span>
      <span class="anim-txt"> - </span>
      <span class="anim-txt"> GC </span>
    </div>
    <div class="connect">
      <div class="erreur" v-if="msgErr != ''">{{ msgErr }}</div>
      <div class="adm">
        <p @click="affDivAdm">{{ txtLienAdmin }}</p>
      </div>
      <div v-if="affAdm" class="container">
        <input
          v-model="admLogin"
          type="text"
          name="admLogin"
          placeholder="Login administrateur"
          id="admLogin"
        />
        <input
          v-model="admMdp"
          type="password"
          name="admMpd"
          placeholder="mot de passe administrateur"
          id="admmpd"
        />
        <input
          v-model="admVerif"
          type="password"
          name="admVerif"
          placeholder="Champ de vérification"
          id="admVerif"
          @keyup.enter="connectAdmin"
        />
        <input
          @click="connectAdmin"
          type="submit"
          value="Connect"
          class="admbtn"
        />
      </div>
      <div v-else class="container">
        <input
          v-model="codeEntreprise"
          type="text"
          name="codeEntreprise"
          placeholder="Code Entreprise"
        />
        <input v-model="login" type="text" name="login" placeholder="login" />
        <input
          v-model="mdp"
          type="password"
          name="mdp"
          placeholder="mot de passe"
          @keyup.enter="soumettre"
        />
        <input
          @click="soumettre"
          type="submit"
          name="btn_submit"
          id="btn_submit"
          class="btn_submit"
          value="Connexion"
        />
      </div>
    </div>
    <div class="wait" v-if="wait == true">
      <img src="/assets/charge.gif" alt="" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";

import gsap from "gsap";
import { duration } from "moment";
import VueCookies from "vue-cookies";

axios.defaults.withCredentials = true;

export default {
  name: "Home",
  data() {
    return {
      login: "",
      mdp: "",
      affAdm: false,
      admLogin: "",
      admMdp: "",
      admVerif: "",
      affErrAdmin: false,
      txtLienAdmin: "Espace Utilisateur",
      codeEntreprise: "",
      msgErr: "",
      wait: false,
    };
  },
  methods: {
    soumettre(e) {
      e.preventDefault();
      this.wait = true;
      this.msgErr = "";
      axios({
        method: "post",
        //url: this.$store.state.server + "/user/auth",
        url: this.$store.state.server + "/user/auth",
        data: {
          login: this.login,
          mdp: this.mdp,
          codeEntreprise: this.codeEntreprise,
        },
        withCredentials: true,
      })
        .then((reponse) => {
          console.log(reponse);
          if (reponse.data.user == "OK") {
            this.$store.commit("SauveLogin", {
              idUser: reponse.data.userId,
              login: reponse.data.login,
            });
            VueCookies.set("atom_user_id", reponse.data.userId, "6h");
            VueCookies.set("atom_user_login", reponse.data.login, "6h");
            VueCookies.set("atom_code_entreprise", this.codeEntreprise, "6h");
            VueCookies.set("jwtAtomGC", reponse.data.jwtAtomGC);
            this.$store.commit("ListeParams");

            this.$router.push({ path: "userhub" });
          } else {
            VueCookies.set("atom_user_id", "0", "1s");
            VueCookies.set("atom_user_login", "", "1s");
            VueCookies.set("atom_code_entreprise", "", "1s");
            this.msgErr =
              "Login ou mot de passe incorrect, connexion impossible";
            VueCookies.set("jwtUserAtomGC", "", "1s");
            //alert("Login ou mot de passe incorrect, connexion impossible");
            this.wait = false;
          }
        })
        .catch((erreur) => {
          console.log(erreur);
          this.msgErr = "Impossible de se connecter à la base";
          this.wait = false;
        });
    },
    affDivAdm() {
      this.msgErr = "";
      if (this.affAdm == true) {
        this.affAdm = false;
        this.txtLienAdmin = "Espace Utilisateur";
      } else {
        this.affAdm = true;
        this.txtLienAdmin = "Espace Administrateur";
      }
      console.log(this.affAdm);
    },
    connectAdmin(e) {
      e.preventDefault();
      this.msgErr = "";
      this.wait = true;
      //console.log(this.admMdp);
      axios({
        method: "post",
        url: this.$store.state.server + "/ConnectAdmin",
        data: { login: this.admLogin, mdp: this.admMdp, Verif: this.admVerif },
        withCredentials: true,
        headers: { authorization: VueCookies.get("jwtAtomGC") },
      })
        .then((reponse) => {
          console.log(reponse);
          if (reponse.data.admin === "YES") {
            this.$router.push({ path: "espace-admin" });
          } else {
            console.log("erreur");
            this.msgErr =
              "Login ou mot de passe incorrect, connexion impossible";
          }
        })
        .catch((erreur) => {
          console.log(erreur);
          this.msgErr = "Login ou mot de passe incorrect, connexion impossible";
          this.wait = false;
        });
    },
  },
  mounted() {
    const TLine = new gsap.timeline();

    TLine.to(".anim-txt", {
      opacity: 1,
      top: 150,
      duration: 1,
      stagger: 0.3,
      ease: "power2.inOut",
    }).to(".connect", { opacity: 1, duration: 0.8 });
    //.to("anim-txt", { display: none})
  },
};
</script>

<style scoped lang="scss">
body {
  background-color: azure;
  margin: 0;
  box-sizing: border-box;
}

.home {
  /*flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;*/

  height: 100vh;
  background: url("/assets/fond/fond-login.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;

  .anim {
    width: 100%;
    text-align: center;
    position: absolute;

    span {
      position: relative;
      top: 200px;
      opacity: 0;
      font-size: 75px;
      font-weight: bold;
      color: white;
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
    }
  }

  .connect {
    position: relative;
    opacity: 0;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;

    width: 400px;
    background: inherit;
    border: none;
    box-shadow: 0 0 10px rgba(172, 139, 139, 0.6);
    border-radius: 5px;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    z-index: 1;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      background: inherit;
      z-index: -1;
      inset: 0;
      filter: blur(3px);
    }

    .adm {
      margin: 8px 0 20px 0;

      width: 100%;
      height: 30px;
      text-align: center;
      font-weight: 600;

      p {
        padding: 0 10px;
        cursor: pointer;
        color: white;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      }
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 400px;

      input {
        width: 350px;
        padding: 0.5em;
        margin: 10px 0;
        text-align: center;
      }

      .btn_submit {
        /*'width: 90%;*/
        width: 150px;
        padding: 0.5em;
        background-color: rgba(141, 189, 252, 0.6);
        border-radius: 2px;
        border: gray 1px solid;
        transition: 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: rgba(115, 175, 253, 0.9);
        }

        &:active {
          background-color: rgba(115, 175, 253, 1);
        }
      }
    }

    .petitmdp {
      /*display: flex;
          justify-content: center;
          flex-direction: row;*/
      width: 90%;
      border: gray 1px solid;
      border-radius: 3px;
      height: 55px;
      margin: 2px auto;

      input {
        padding: 0.5em;
      }

      #admmpd {
        width: 60%;
      }
      #admbtn {
        width: 30%;
        margin: 0 0 0 5px;
      }

      .affErrAdmin {
        border: red 1px solid;
        border-radius: 5px;
        background-color: lightsalmon;
        color: red;
        width: 90%;
        height: 40px;
      }
    }
  }
  .wait {
    width: 50px;
    height: 50px;
    position: absolute;
    opacity: 1;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);

    img {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
