import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/espace-admin",
    name: "EspaceAdmin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EspaceAdmin.vue"),
  },
  {
    path: "/userhub",
    name: "UserHub",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/userHub.vue"),
  },
  {
    path: "/impression",
    name: "impression",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/impression.vue"),
  },
  {
    path: "/listing",
    name: "listing",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/listing.vue"),
  },
];

const router = createRouter({
  //mode: "history",
  history: createWebHashHistory(),
  //history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
